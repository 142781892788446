<template>
  <QDialog
    v-model="authStore.showRegistrationModal"
    persistent
  >
    <div class="!max-w-screen w-full md:w-6xl pt-6 px-6 !shadow-none">
      <div class="relative dark:bg-dark-2 light:bg-light-2">
        <QBtn
          v-close-popup
          to="/"
          icon="close"
          flat
          round
          dense
          text-color="white"
          size="18px"
          class="absolute -top-6 -right-6 bg-secondary"
        />
        <QForm
          class="flex flex-col gap-5 p-5 md:p-10"
          @submit.prevent="submit(form)"
        >
          <div class="flex flex-nowrap items-center justify-center gap-2 w-full border-b-2 dark:border-dark-4 light:border-light-4 text-base md:text-2xl pb-8 font-bold ">
            <Logo
              class="w-15 md:w-45"
              :dark="$q.dark.isActive"
            />
          </div>
          <div class="border-b dark:border-dark-4 light:border-light-4 h-42 grid md:(h-20 grid-cols-2 gap-5) gap-2 pb-2">
            <div class="flex md:flex-nowrap items-center justify-between gap-2">
              <div class="flex items-center gap-2">
                <QIcon
                  name="person"
                  size="xs"
                  color="secondary"
                />
                아이디
              </div>
              <QInput
                v-model="form.username"
                dense
                outlined
                placeholder="영어,숫자 4글자이상"
                :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                class="max-w-85 w-full border-dark-2"
              />
            </div>
            <div class="flex md:flex-nowrap items-center justify-between gap-2">
              <div class="flex items-center gap-2">
                <QIcon
                  name="border_color"
                  size="xs"
                  color="secondary"
                />
                닉네임
              </div>
              <QInput
                v-model="form.nickname"
                dense
                outlined
                placeholder="한글만 입력하세요"
                :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                class="max-w-85 w-full border-dark-2"
              />
            </div>
          </div>
          <div class="border-b dark:border-dark-4 light:border-light-4 h-42 grid md:(h-20 grid-cols-2 gap-5) gap-2 pb-2">
            <div class="flex md:flex-nowrap items-center justify-between gap-2">
              <div class="flex items-center gap-2">
                <QIcon
                  name="lock"
                  size="xs"
                  color="secondary"
                />
                로그인 비밀번호
              </div>
              <QInput
                v-model="form.password"
                dense
                outlined
                placeholder="4~8자만 입력하세요"
                type="password"
                :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                class="max-w-85 w-full border-dark-2"
              />
            </div>
            <div class="flex md:flex-nowrap items-center justify-between gap-2">
              <div class="flex items-center gap-2">
                <QIcon
                  name="lock"
                  size="xs"
                  color="secondary"
                />
                로그인 비밀번호확인
              </div>
              <QInput
                v-model="form.password_confirmation"
                dense
                outlined
                placeholder="비밀번호 확인"
                type="password"
                :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                class="max-w-85 w-full border-dark-2"
              />
            </div>
          </div>
          <div class="border-b dark:border-dark-4 light:border-light-4 h-42 grid md:(h-20 grid-cols-2 gap-5) gap-2 pb-2">
            <div class="flex md:flex-nowrap items-center justify-between gap-2">
              <div class="flex items-center gap-2">
                <QIcon
                  name="call"
                  size="xs"
                  color="secondary"
                />
                휴대폰
              </div>
              <div class="flex flex-nowrap items-center gap-2 max-w-85 w-full">
                <QInput
                  v-model="form.mobile"
                  dense
                  outlined
                  placeholder="본인 휴대폰 번호를 입력하세요"
                  :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                  class="w-full"
                >
                  <template #prepend>
                    <div class="text-sm">
                      {{ `+(${countryCode})` }}
                    </div>
                  </template>
                </QInput>
              </div>
            </div>
            <div class="flex md:flex-nowrap items-center justify-between gap-2">
              <div class="flex items-center gap-2">
                <QIcon
                  name="credit_card"
                  size="xs"
                  color="secondary"
                />
                예금주
              </div>
              <QInput
                v-model="form.account_name"
                dense
                outlined
                placeholder="본인 이름을 입력하세요"
                :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                class="max-w-85 w-full border-dark-2"
              />
            </div>
          </div>
          <div class="border-b dark:border-dark-4 light:border-light-4 h-42 grid md:(h-20 grid-cols-2 gap-5) gap-2 pb-2">
            <div class="flex md:flex-nowrap items-center justify-between gap-2">
              <div class="flex items-center gap-2">
                <QIcon
                  name="account_balance"
                  size="xs"
                  color="secondary"
                />
                출금은행
              </div>
              <QSelect
                v-model="form.bank_id"
                :options="bankOptions"
                emit-value
                map-options
                dense
                outlined
                options-dense
                placeholder="은행명을 선택하세요 "
                :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                class="w-full max-w-85"
              >
                <template #selected-item="{opt}">
                  <template v-if="form.bank_id">
                    {{ opt.label }}
                  </template>
                  <div v-else>
                    은행명을 선택하세요
                  </div>
                </template>
              </QSelect>
            </div>
            <div class="flex md:flex-nowrap items-center justify-between gap-2">
              <div class="flex items-center gap-2">
                <QIcon
                  name="credit_card"
                  size="xs"
                  color="secondary"
                />
                출금계좌번호
              </div>
              <QInput
                v-model="form.card_number"
                dense
                outlined
                placeholder="계좌번호를 입력하세요 "
                :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                class="max-w-85 w-full border-dark-2"
              />
            </div>
          </div>
          <div>
            <QBtn
              type="submit"
              color="secondary"
              rounded
              :loading="authStore.registering"
              class="w-full h-15"
            >
              가입하기
            </QBtn>
          </div>
        </QForm>
      </div>
    </div>
  </QDialog>
</template>

<script setup>
import Logo from '@/components/Logo.vue'
import {useAuthStore} from '@/stores/auth'
import {computed, onMounted, reactive, ref} from 'vue'
import {useRequest} from '@/composables/useRequest'
import {whenever} from '@vueuse/core'
import dayjs from "dayjs";

const authStore = useAuthStore()
const code = ref('')

const countryCode = '82'
const captchaTime = ref(60)
const num = ref(0)
const verifyBtnDisable = ref(false)
const sendVerifyBtnLabel = ref('인증번호발송')


const form = reactive({
  username: '',
  nickname: '',
  mobile: '',
  password: '',
  password_confirmation: '',
  bank_id: '',
  account_name: '',
  card_number: '',
  invite_code: code,
  // sms_code: '',
  // sms_request_id: ''
})
const { data: banks, execute: fetch } = useRequest('banks')
fetch()
const bankOptions = computed(() => banks.value?.data.map(bank => ({ label: bank.name, value: bank.id })))
const appName = computed(() => import.meta.env.VITE_APP_NAME)

async function submit(){
  await authStore.register(form)
  authStore.showRegistrationModal = false
  localStorage.removeItem('verify_start_time')
}

onMounted(() => {
  const startTime = localStorage.getItem('verify_start_time')
  num.value = dayjs().unix() - startTime
  captchaTime.value = num.value > 60 ? 60 : 60 - num.value
})

async function sendVerificationCode(){
  verifyBtnDisable.value = captchaTime.value !== 60

  if (captchaTime.value === 60){
    localStorage.setItem('verify_start_time', dayjs().unix().toString())

    const verifyForm = reactive({
      mobile: `${countryCode}${form.mobile}`,
      app_name: appName,
    })

    const verifyRequestId = localStorage.getItem('verify_request_id')
    if (verifyRequestId){
      let verifyRequestArr = verifyRequestId.split('_')
      if (dayjs().unix() - verifyRequestArr[1] < 1800){
        verifyForm.sms_request_id = verifyRequestArr[0]
      }
    }
    await authStore.verify(verifyForm)

    form.sms_request_id = authStore.verifyData.request_id
    localStorage.setItem('verify_request_id', `${authStore.verifyData.request_id}_${dayjs().unix().toString()}`)
  }

  sendVerifyBtnLabel.value = captchaTime.value.toString()
  const timer = setInterval(() => {
    if (captchaTime.value === 1){
      clearInterval(timer)
      captchaTime.value = 60
      sendVerifyBtnLabel.value = '인증번호재전송'
      verifyBtnDisable.value = false
    }else{
      captchaTime.value--
      sendVerifyBtnLabel.value = captchaTime.value.toString()
    }
  }, 1000)
}

whenever(() => authStore.showRegistrationModal, () => {
  code.value = localStorage.getItem('code')
})
</script>
